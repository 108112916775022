import { http } from '@/app/helpers/axios-instance';
import { toPageable, toPagination } from '@/app/helpers/pagination';

export const fetchEmails = ({ search, pagination = {} }) => {
  return http
    .get('/v1/emails', { params: { search, ...toPageable(pagination) } })
    .then(response => toPagination(response.data));
};

export const downloadEmail = id =>
  http
    .get('/v1/emails/' + id + '/download', {
      responseType: 'arraybuffer',
    })
    .then(response => ({ response: response }));
