<template>
  <div class="container-fluid align-left">
    <div class="top-container-wrapper">
      <p class="list-title">Emails</p>
    </div>
    <div>
      <DataTable
        :columns="columns"
        :fetch-function="fetchEmails"
        :route-name="'EmailsSearch'"
      />
    </div>
  </div>
</template>

<script>
import DataTable from '@/app/components/base/DataTable';
import { fetchEmails, downloadEmail } from '@/app/api/emails';
import { useContextMenu } from '@/app/use/contextMenu';
import { h } from 'vue';
import Icon from '@/app/components/base/Icon';

const columns = [
  {
    title: 'Id',
    key: 'id',
    sorter: () => {},
    isVisible: true,
  },
  {
    title: 'To',
    key: 'toAddress',
    sorter: () => {},
    isVisible: true,
  },
  {
    title: 'From',
    key: 'fromAddress',
    sorter: () => {},
    isVisible: true,
  },
  {
    title: 'Subject',
    key: 'subject',
    isVisible: true,
  },
  {
    title: 'HtmlBody',
    key: 'id',
    render(row) {
      return h(Icon, {
        name: 'file_download',
        onClick: () => {
          downloadEmail(row.id).then(({ response }) => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', 'mail_' + row.id + '.html');
            document.body.appendChild(fileLink);
            fileLink.click();
          });
        },
      });
    },
    isVisible: true,
  },
  {
    title: 'ReplyTo',
    key: 'replyToAddress',
    isVisible: false,
  },
  {
    title: 'ExternalId',
    key: 'externalId',
    isVisible: false,
  },
  {
    title: 'Status',
    key: 'status',
    isVisible: true,
  },
  {
    title: 'CreatedAt',
    key: 'createdAt',
    sorter: () => {},
    isVisible: true,
  },
];

export default {
  name: 'EmailsList',
  components: { DataTable },
  setup() {
    const { setMenuItems } = useContextMenu();
    setMenuItems([]);

    return {
      fetchEmails,
      columns,
    };
  },
};
</script>

<style scoped lang="scss">
.list-title {
  text-align: left;
  font-size: 1.5rem;
  font-weight: 700;
}

.top-container-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0.5rem 0;
}

.btn-text {
  margin-left: 1rem;
}

@media (max-width: 767px) {
  .top-container-wrapper {
    flex-direction: column;
  }
}
</style>
